import {
  setInitialDate,
  setFinalDate,
  setDateFilterType,
  setUserFilters,
} from "../leadsSlice";
import moment from "moment";
import { getUserDataAction } from "./getUserDataAction";

export const onDaysSubtractAction = (value) => {
  return async (dispatch, getState) => {
    const endDate = moment().format("YYYY-MM-DD");
    const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
    dispatch(setInitialDate(null));
    dispatch(setFinalDate(null));
    dispatch(setDateFilterType(value === 7 ? "7days" : "31days"));
    dispatch(
      setUserFilters({
        ...getState().leads.userFilters,
        endDate,
        startDate,
        dateFilterType: value === 7 ? "7days" : "31days",
      })
    );
    dispatch(getUserDataAction());
  };
};
