import { functions } from "../../../firebase";
import { httpsCallable } from "firebase/functions";
import { setUserData } from "../../../redux/slices/authentication/authenticationSlice";

const addContact = (contact, setLoading, dispatch, profile) => {
  setLoading(true);

  const add = httpsCallable(functions, "addContact");
  const response = add({ contact })
    .then((res) => {
      let bookmarks = profile?.bookmarks || [];
      let newBookmarks = [...bookmarks, res.data.evaluation];
      const lastAcceptedContactDate = res.data.lastAcceptedContactDate;
      dispatch(
        setUserData({
          ...profile,
          bookmarks: newBookmarks,
          lastAcceptedContactDate,
        })
      );
      setLoading(false);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err);
      return err;
    });

  return response;
};

export default addContact;
