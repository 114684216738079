import { useState, useEffect, useRef, useMemo } from "react";
import ProspectCard from "./prospectCard";
import ReactPaginate from "react-paginate";
import { useTranslation } from "react-i18next";
import LoadMore from "../../../components/buttons/loadMore";
import fetchMoreData from "../utils/fetchMoreData";
import Spinner from "../../../components/spinner";
import Filters from "../newFilters";
import Timer from "./Timer";
import { useSelector } from "react-redux";

const ProspectsList = ({
  setIsRestricted,
  refreshFilter,
  onCityChange,
  onStatusChange,
  onDaysSubtract,
  onDateChange,
  updateFinalDate,
  updateInitialDate,
  updateDateFilterType,
  updateCurrentMunicipality,
  updateFilterSummary,
  updateSelectedMarker,
  profile,
  refreshUserData,
  updateEvaluation,
  setLoading,
  onOwnerChange,
  setAll,
  setEvals,
  setReports,
  setNextProspectId,
  sortEvaluationsByDate,
  sortEvaluationsByValeurVMZ,
  setLeadsCount,
  leadsCount,
  updateFetchMoreLoading,
  setRefKey,
  isScreenWidthXl,
}) => {
  const { t } = useTranslation();

  const {
    All,
    evaluations,
    myEvaluations,
    initialDate,
    finalDate,
    filters,
    userFilters,
    cities,
    muncipalities,
    projectStatus,
    cityValue,
    munciValue,
    projectValue,
    ownerValue,
    dateFilterType,
    currentMunicipality,
    limit,
    nextProspectId,
    sortedBy,
    sortingType,
    shownProspects,
    isRestricted,
    fetchMoreLoading,
    loadingLeads,
  } = useSelector((state) => state.leads);

  const cardRef = useRef(null);

  const [selectedProspect, setSelectedProspect] = useState(null);

  const [opportunities, setOpportunities] = useState(evaluations);
  const [mesOpportunities, setMesOpportunities] = useState(myEvaluations);

  useEffect(() => {
    setOpportunities(evaluations);
    setRefKey((prevKey) => prevKey + 1);
    if (sortedBy === "date") {
      sortEvaluationsByDate(evaluations, sortingType, setOpportunities);
    } else if (sortedBy === "valeurVMZ") {
      sortEvaluationsByValeurVMZ(evaluations, sortingType, setOpportunities);
    }
  }, [
    evaluations,
    shownProspects,
    setRefKey,
    sortedBy,
    sortingType,
    setOpportunities,
    sortEvaluationsByDate,
    sortEvaluationsByValeurVMZ,
  ]);

  useEffect(() => {
    setMesOpportunities(myEvaluations);
    if (sortedBy === "date") {
      sortEvaluationsByDate(myEvaluations, sortingType, setMesOpportunities);
    } else if (sortedBy === "valeurVMZ") {
      sortEvaluationsByValeurVMZ(
        myEvaluations,
        sortingType,
        setMesOpportunities
      );
    }
    setRefKey((prevKey) => prevKey + 1);
  }, [
    myEvaluations,
    shownProspects,
    setRefKey,
    sortedBy,
    sortingType,
    setMesOpportunities,
    sortEvaluationsByDate,
    sortEvaluationsByValeurVMZ,
  ]);

  const [displayedProspects, setDisplayedProspects] = useState(opportunities);

  const values = {
    1: t("Leads.83"),
    2: t("Leads.84"),
  };

  const [firstIndexToContact, setFirstIndexToContact] = useState(0);
  const [lastIndexToContact, setLastIndexToContact] = useState(5);

  const [selectedPageNumber, setSelectedPageNumber] = useState(0);

  useEffect(() => {
    if (shownProspects === "opportunities") {
      setDisplayedProspects(opportunities);
    } else if (shownProspects === "mesopportunities")
      setDisplayedProspects(
        mesOpportunities.slice(firstIndexToContact, lastIndexToContact)
      );
    setRefKey((prevKey) => prevKey + 1);
  }, [
    opportunities,
    mesOpportunities,
    shownProspects,
    firstIndexToContact,
    lastIndexToContact,
    setRefKey,
  ]);

  useEffect(() => {
    setFirstIndexToContact(0);
    setLastIndexToContact(5);
    setSelectedPageNumber(0);
    setSelectedProspect(null);
  }, [shownProspects]);

  const handlePageChange = (event) => {
    const indexOfLastPost = (event.selected + 1) * 5;
    const indexOfFirstPost = indexOfLastPost - 5;
    if (event.selected === 0) {
      setFirstIndexToContact(0);
      setLastIndexToContact(5);
    } else {
      setFirstIndexToContact(indexOfFirstPost);
      setLastIndexToContact(indexOfLastPost);
    }
    setSelectedPageNumber(event.selected);
  };

  useEffect(() => {}, [displayedProspects]);

  const prospectListStyle = useMemo(() => {
    if (shownProspects === "opportunities" && isScreenWidthXl) {
      return {
        height: "calc(100vh - 400px)",
        width: "100%",
        overflowY: "scroll",
      };
    } else {
      return {
        width: "100%",
      };
    }
  }, [shownProspects, isScreenWidthXl]);

  return (
    <div className="newLeads_prospects_list">
      {(fetchMoreLoading || loadingLeads) && <Spinner />}
      <div
        className="col"
        style={{
          width: "100%",
        }}
      >
        {shownProspects === "opportunities" && (
          <Filters
            cities={cities}
            filters={filters}
            cityValue={cityValue}
            onCityChange={onCityChange}
            muncipalities={muncipalities}
            munciValue={munciValue}
            ownerValue={ownerValue}
            onOwnerChange={onOwnerChange}
            onStatusChange={onStatusChange}
            onDaysSubtract={onDaysSubtract}
            refreshFilter={refreshFilter}
            onDateChange={onDateChange}
            finalDate={finalDate}
            setFinalDate={updateFinalDate}
            initialDate={initialDate}
            setInitialDate={updateInitialDate}
            projectValue={projectValue}
            projectStatus={projectStatus}
            dateFilterType={dateFilterType}
            setDateFilterType={updateDateFilterType}
            userFilters={userFilters}
            currentMunicipality={currentMunicipality}
            setCurrentMunicipality={updateCurrentMunicipality}
            updateFilterSummary={updateFilterSummary}
          />
        )}
        {isRestricted && (
          <Timer
            lastAcceptedContactDate={profile?.lastAcceptedContactDate}
            setIsRestricted={setIsRestricted}
          />
        )}
      </div>
      <div className="newLeads_prospects_list_head" ref={cardRef}>
        <div className="">
          <span>{t("Leads.106")}</span>
        </div>
        <div className="d-none d-md-flex">
          <span>{t("Leads.107")}</span>
        </div>
        <div className="d-none d-lg-flex">
          <span>{t("Leads.108")}</span>
        </div>
        <div>
          <span>{t("Leads.109")}</span>
          <span className="newLeads_prospects_list_head_icons">
            <img
              src="assets/icons/upArrow.svg"
              alt="search"
              onClick={() =>
                sortEvaluationsByDate(
                  shownProspects === "mesopportunities"
                    ? mesOpportunities
                    : opportunities,
                  "asc",
                  shownProspects === "mesopportunities"
                    ? setMesOpportunities
                    : setOpportunities
                )
              }
            />
            <img
              src="assets/icons/downArrow.svg"
              alt="search"
              onClick={() =>
                sortEvaluationsByDate(
                  shownProspects === "mesopportunities"
                    ? mesOpportunities
                    : opportunities,
                  "desc",
                  shownProspects === "mesopportunities"
                    ? setMesOpportunities
                    : setOpportunities
                )
              }
            />
          </span>
        </div>
        <div className="d-none d-lg-flex">
          <span>{t("Leads.110")}</span>
          <span className="newLeads_prospects_list_head_icons">
            <img
              src="assets/icons/upArrow.svg"
              alt="search"
              onClick={() =>
                sortEvaluationsByValeurVMZ(
                  shownProspects === "mesopportunities"
                    ? mesOpportunities
                    : opportunities,
                  "asc",
                  shownProspects === "mesopportunities"
                    ? setMesOpportunities
                    : setOpportunities
                )
              }
            />
            <img
              src="assets/icons/downArrow.svg"
              alt="search"
              onClick={() =>
                sortEvaluationsByValeurVMZ(
                  shownProspects === "mesopportunities"
                    ? mesOpportunities
                    : opportunities,
                  "desc",
                  shownProspects === "mesopportunities"
                    ? setMesOpportunities
                    : setOpportunities
                )
              }
            />
          </span>
        </div>
        <div className="newLeads_prospects_list_head_actiontype">
          <span>{t("Leads.111")}</span>
        </div>
      </div>
      <div style={prospectListStyle}>
        {displayedProspects.map((prospect) => (
          <ProspectCard
            key={prospect.id}
            prospect={prospect}
            selectedProspect={selectedProspect}
            setSelectedProspect={setSelectedProspect}
            onClick={updateSelectedMarker}
            profile={profile}
            refreshUserData={refreshUserData}
            updateEvaluation={updateEvaluation}
            setLoading={setLoading}
            shownProspects={shownProspects}
            userFilters={userFilters}
          />
        ))}
        {shownProspects === "opportunities" && nextProspectId && (
          <div className="newLeads_pagination_leadmore">
            <LoadMore
              loadMore={() =>
                fetchMoreData(
                  userFilters,
                  updateFetchMoreLoading,
                  ownerValue,
                  limit,
                  nextProspectId,
                  onOwnerChange,
                  setAll,
                  All,
                  setEvals,
                  setReports,
                  setNextProspectId,
                  t("Leads.79"),
                  leadsCount,
                  setLeadsCount
                )
              }
            />
          </div>
        )}
      </div>
      <div className="newLeads_pagination_container">
        {shownProspects === "mesopportunities" && (
          <ReactPaginate
            previousLabel={values["1"]}
            nextLabel={values["2"]}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(mesOpportunities.length / 5)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
            forcePage={selectedPageNumber}
          />
        )}
      </div>
    </div>
  );
};

export default ProspectsList;
