import { functions } from "../../../../firebase";
import { httpsCallable } from "firebase/functions";
import {
  setMuncipalities,
  setInitialDate,
  setFinalDate,
  setDateFilterType,
  setCities,
  setFilters,
  setUserFilters,
  setCityValue,
  setMunciValue,
  setFirstFetchLoading,
} from "../leadsSlice";
import { municipalitiesFormatter } from "../../../../pages/prospect/utils/municipalitiesFormatter";
import { extractMunicipalitiesFromFilterSummary } from "../../../../pages/prospect/utils/extractMunicipalitiesFromFilterSummary";
import { getUserDataAction } from "./getUserDataAction";

export const getUserFiltersAction = () => {
  return async (dispatch, getState) => {
    dispatch(setFirstFetchLoading(true));
    const defaultFilters = httpsCallable(functions, "defaultFilters");
    defaultFilters()
      .then((res) => {
        const cities = [];

        for (const [key, val] of Object.entries(res.data.filters)) {
          cities.push(key);
        }
        if (res.data.defaultFilters.dateFilterType === "custom") {
          dispatch(setInitialDate(res.data.defaultFilters.startDate));
          dispatch(setFinalDate(res.data.defaultFilters.endDate));
          dispatch(setDateFilterType("custom"));
        } else {
          dispatch(setDateFilterType(res.data.defaultFilters.dateFilterType));
        }
        dispatch(
          setCities(
            municipalitiesFormatter(cities.sort((a, b) => (a > b ? 1 : -1)))
          )
        );
        dispatch(setFilters(res.data.filters));
        dispatch(setUserFilters(res.data.defaultFilters));
        dispatch(getUserDataAction());
        const selectedMunicipalites = extractMunicipalitiesFromFilterSummary(
          res.data.defaultFilters?.filterSummary
        );
        // const city = res.data.defaultFilters.city;
        const sortedMuncipalite = selectedMunicipalites.map((v) => ({
          label: v,
          value: v,
        }));
        // dispatch(setCityValue({ label: city, value: city }));
        dispatch(setMunciValue(sortedMuncipalite));
        dispatch(setFirstFetchLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};
