import { useEffect, useCallback, useRef, useState, useReducer } from "react";
import Spinner from "../../components/spinner";
import "./newleads.scss";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { isWithinInterval, subMinutes } from "date-fns";

import { getUserFiltersAction } from "../../redux/slices/leads/actions/getUserFiltersAction";
import { changeEvaluationsAction } from "../../redux/slices/leads/actions/changeEvaluationsAction";
import { getUserDataAction } from "../../redux/slices/leads/actions/getUserDataAction";
import { refreshUserDataAction } from "../../redux/slices/leads/actions/refreshUserDataAction";
import { updateCityAction } from "../../redux/slices/leads/actions/updateCityAction";
import { onOwnerChangeAction } from "../../redux/slices/leads/actions/onOwnerChangeAction";
import { onDaysSubtractAction } from "../../redux/slices/leads/actions/onDaysSubtractAction";
import { refreshFilterAction } from "../../redux/slices/leads/actions/refreshFilterAction";
import { updateFilterSummaryAction } from "../../redux/slices/leads/actions/updateFilterSummaryAction";
import { updateEvaluationAction } from "../../redux/slices/leads/actions/updateEvaluationAction";
import { onStatusChangeAction } from "../../redux/slices/leads/actions/onStatusChangeAction";
import { sortEvaluationsByDateAction } from "../../redux/slices/leads/actions/sortEvaluationsByDateAction";
import { sortEvaluationsByValeurVMZAction } from "../../redux/slices/leads/actions/sortEvaluationsByValeurVMZAction";
import { filterDuplicatedEvaluationsAction } from "../../redux/slices/leads/actions/filterDuplicatedEvaluationsAction";
import { fetchNewProspectsAction } from "../../redux/slices/leads/actions/fetchNewProspectsAction";
import { clearStateAction } from "../../redux/slices/leads/actions/clearStateAction";
import {
  setAll,
  setEvaluations,
  setMyEvaluations,
  setInitialDate,
  setFinalDate,
  setUserFilters,
  setReports,
  setSelectedMarker,
  setDateFilterType,
  setCurrentMunicipality,
  setProspectsCount,
  setNextProspectId,
  setSortedBy,
  setSortingType,
  setShownProspects,
  setIsRestricted,
  setLoading,
  setFetchMoreLoading,
} from "../../redux/slices/leads/leadsSlice";
import checkForNewLeads from "./utils/checkForNewLeads";

import ProspectsMap from "../../components/map";
import Opportunities from "./NewSections/Opportunities";
import ProspectsList from "./NewSections/prospectsList";
import RefreshButton from "./NewSections/refreshButton";

import { useNavigate } from "react-router-dom";

const ProspectsPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const componentRef = useRef();

  const [firstLoad, setFirstLoad] = useState(true);

  const { userData: profile } = useSelector((state) => state.authentication);
  const { userData } = useSelector((state) => state.authentication);

  const {
    evaluations,
    myEvaluations,
    initialDate,
    finalDate,
    userFilters,
    selectedMarker,
    prospectsCount,
    markers,
    needToRefresh,
    firstFetchLoading,
  } = useSelector((state) => state.leads);

  const updateFetchMoreLoading = useCallback(
    (value) => {
      dispatch(setFetchMoreLoading(value));
    },
    [dispatch]
  );

  const [isScreenWidthXl, setIsScreenWidthXl] = useState(
    window.innerWidth >= 1200
  );

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1200) {
        setIsScreenWidthXl(true);
      } else {
        setIsScreenWidthXl(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    dispatch(getUserFiltersAction());

    return () => {
      dispatch(clearStateAction());
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(changeEvaluationsAction(profile));
  }, [profile, dispatch]);

  const refreshUserData = useCallback(() => {
    dispatch(refreshUserDataAction());
  }, [dispatch]);

  useEffect(() => {
    if (userFilters.city && userData && firstLoad) {
      dispatch(getUserDataAction());
      setFirstLoad(false);
    }
  }, [userFilters, dispatch, userData, firstLoad]);

  const updateSelectedMarker = useCallback(
    (marker) => {
      dispatch(setSelectedMarker(marker));
    },
    [dispatch]
  );

  const onCityChange = useCallback(
    (e, filters) => {
      dispatch(updateCityAction(e, filters));
    },
    [dispatch]
  );

  const updateFilterSummary = useCallback(
    (municipality, city, setSelectedOptions) => {
      dispatch(
        updateFilterSummaryAction(municipality, city, setSelectedOptions)
      );
    },
    [dispatch]
  );

  const onOwnerChange = useCallback(
    (e) => {
      dispatch(onOwnerChangeAction(e));
    },
    [dispatch]
  );

  const onStatusChange = useCallback(
    (e) => {
      dispatch(onStatusChangeAction(e));
    },
    [dispatch]
  );

  const onDateChange = useCallback(() => {
    dispatch(
      setUserFilters({
        ...userFilters,
        endDate: finalDate,
        startDate: initialDate,
        dateFilterType: "custom",
      })
    );
    dispatch(getUserDataAction());
    onOwnerChange({ value: "all", label: t("Leads.79") });
  }, [dispatch, userFilters, finalDate, initialDate, onOwnerChange, t]);

  const onDaysSubtract = useCallback(
    (value) => {
      dispatch(onDaysSubtractAction(value));
    },
    [dispatch]
  );

  const updateDateFilterType = useCallback(
    (value) => {
      dispatch(setDateFilterType(value));
    },
    [dispatch]
  );

  const refreshFilter = useCallback(() => {
    dispatch(refreshFilterAction(t("Leads.79")));
    onOwnerChange({ value: "all", label: t("Leads.79") });
    updateDateFilterType("31days");
  }, [dispatch, onOwnerChange, updateDateFilterType, t]);

  const updateEvaluation = useCallback(
    (evaluation) => {
      dispatch(updateEvaluationAction(evaluation));
    },
    [dispatch]
  );

  const sortEvaluationsByDate = useCallback(
    (evaluations, sortingType, setState) => {
      dispatch(sortEvaluationsByDateAction(evaluations, sortingType, setState));
    },
    [dispatch]
  );

  const sortEvaluationsByValeurVMZ = useCallback(
    (evaluations, sortingType, setState) => {
      dispatch(
        sortEvaluationsByValeurVMZAction(evaluations, sortingType, setState)
      );
    },
    [dispatch]
  );

  const setMyEvals = useCallback(
    (value) => {
      dispatch(setMyEvaluations(value));
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(filterDuplicatedEvaluationsAction());
  }, [evaluations, myEvaluations, dispatch]);

  const fetchNewProspects = useCallback(() => {
    dispatch(fetchNewProspectsAction());
  }, [dispatch]);

  const refreshProspects = useCallback(() => {
    navigate(0);
  }, [navigate]);

  useEffect(() => {
    if (userFilters && evaluations?.length > 0 && prospectsCount) {
      const interval = setInterval(async () => {
        const res = await checkForNewLeads(
          userFilters,
          prospectsCount,
          evaluations
        );
        if (res?.isNewLeads) {
          fetchNewProspects();
        }
      }, 300000);
      return () => clearInterval(interval);
    }
  }, [evaluations, userFilters, prospectsCount, dispatch, fetchNewProspects]);

  const updateShownProspects = useCallback(
    (value) => {
      dispatch(setShownProspects(value));
    },
    [dispatch]
  );

  const updateSortedBy = useCallback(
    (value) => {
      dispatch(setSortedBy(value));
    },
    [dispatch]
  );

  const updateSortingType = useCallback(
    (value) => {
      dispatch(setSortingType(value));
    },
    [dispatch]
  );

  const updateFinalDate = useCallback(
    (value) => {
      dispatch(setFinalDate(value));
    },
    [dispatch]
  );

  const updateInitialDate = (value) => {
    dispatch(setInitialDate(value));
  };

  const updateCurrentMunicipality = (value) => {
    dispatch(setCurrentMunicipality(value));
  };

  const updateLoading = useCallback(
    (value) => {
      dispatch(setLoading(value));
    },
    [dispatch]
  );

  const updateAll = useCallback(
    (value) => {
      dispatch(setAll(value));
    },
    [dispatch]
  );

  const setEvals = useCallback(
    (value) => {
      dispatch(setEvaluations(value));
    },
    [dispatch]
  );

  const updateReports = useCallback(
    (value) => {
      dispatch(setReports(value));
    },
    [dispatch]
  );

  const updateNextProspectId = useCallback(
    (value) => {
      dispatch(setNextProspectId(value));
    },
    [dispatch]
  );

  const updateProspectsCount = useCallback(
    (value) => {
      dispatch(setProspectsCount(value));
    },
    [dispatch]
  );

  useEffect(() => {
    if (
      profile?.lastAcceptedContactDate &&
      isWithinInterval(new Date(profile.lastAcceptedContactDate), {
        start: subMinutes(new Date(), 15),
        end: new Date(),
      })
    ) {
      dispatch(setIsRestricted(true));
    } else {
      dispatch(setIsRestricted(false));
    }
  }, [profile?.lastAcceptedContactDate, dispatch]);

  const [componentHeight, setComponentHeight] = useState(0);

  const handleHeightChange = useCallback((componentRef) => {
    if (componentRef.current) {
      setComponentHeight(componentRef.current.clientHeight);
    }
  }, []);

  const [refKey, setRefKey] = useState(0);

  useEffect(() => {
    if (!componentRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      handleHeightChange(componentRef);
    });
    resizeObserver.observe(componentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, [componentRef, handleHeightChange, refKey]);

  return (
    <>
      {needToRefresh && <RefreshButton refresh={refreshProspects} />}
      {profile?.role && profile?.role !== "Essentiel" ? (
        <div>
          <section className="pb-4 poppins_font">
            <div className="mb-4">
              <Opportunities
                evaluationsLength={evaluations.length}
                myEvaluationsLength={myEvaluations.length}
                updateShownProspects={updateShownProspects}
                updateSortedBy={updateSortedBy}
                updateSortingType={updateSortingType}
              />
            </div>
            <div ref={componentRef}>
              <ul className="nav row gy-3">
                <li className="col-12 col-lg-12 col-xl-4">
                  <div
                    style={{
                      minHeight: "20rem",
                      maxHeight: "900px",
                      height: "100%",
                    }}
                  >
                    <ProspectsMap
                      markers={markers}
                      selectedMarker={selectedMarker}
                      updateSelectedMarker={updateSelectedMarker}
                      isFixed={true}
                      componentHeight={componentHeight}
                      isScreenWidthXl={isScreenWidthXl}
                    />
                  </div>
                </li>
                {firstFetchLoading ? (
                  <Spinner />
                ) : (
                  <li className="col col-xl-8 gx-0 px-0">
                    <ProspectsList
                      setIsRestricted={setIsRestricted}
                      onCityChange={onCityChange}
                      onOwnerChange={onOwnerChange}
                      onStatusChange={onStatusChange}
                      onDaysSubtract={onDaysSubtract}
                      refreshFilter={refreshFilter}
                      onDateChange={onDateChange}
                      updateFinalDate={updateFinalDate}
                      updateInitialDate={updateInitialDate}
                      updateDateFilterType={updateDateFilterType}
                      updateCurrentMunicipality={updateCurrentMunicipality}
                      updateFilterSummary={updateFilterSummary}
                      setRefKey={setRefKey}
                      updateSelectedMarker={updateSelectedMarker}
                      profile={profile}
                      refreshUserData={refreshUserData}
                      updateEvaluation={updateEvaluation}
                      setLoading={updateLoading}
                      setAll={updateAll}
                      setEvals={setEvals}
                      setReports={updateReports}
                      setNextProspectId={updateNextProspectId}
                      sortEvaluationsByDate={sortEvaluationsByDate}
                      sortEvaluationsByValeurVMZ={sortEvaluationsByValeurVMZ}
                      setLeadsCount={updateProspectsCount}
                      leadsCount={prospectsCount}
                      updateFetchMoreLoading={updateFetchMoreLoading}
                      isScreenWidthXl={isScreenWidthXl}
                    />
                  </li>
                )}
              </ul>
            </div>
          </section>
        </div>
      ) : (
        <div style={{ minHeight: "85vh" }}>
          <Spinner />
        </div>
      )}
    </>
  );
};

export default ProspectsPage;
