import { useTranslation } from "react-i18next";

const PropertyInfo = ({ prospect, accepted }) => {
  const { t } = useTranslation();

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });

  const calculateEstimationHautePercentage = (
    estimationBasse,
    estimationHaute
  ) => {
    return `+${Math.floor(
      ((estimationHaute - estimationBasse) / estimationBasse) * 100
    )}%`;
  };

  const reformulateAdress = (address, accepted) => {
    let newAddress = address;
    if (!accepted) {
      newAddress = address.replace(/^[0-9]+/g, "");
    }
    return newAddress;
  };

  return (
    <div className="newLeads_propertyInfo">
      <div className="newLeads_propertyInfo_first">
        <div>
          <span>{t("Leads.39")}</span>
          <span className="newLeads_prospects_list_item_valeurVMZ">
            {formatter.format(prospect.ziaEstimation.prediction)}
          </span>
        </div>
        <div>
          <span>{t("Leads.40")}</span>
          <span>
            <span className="newLeads_prospects_list_item_EstimationHaute">
              {calculateEstimationHautePercentage(
                prospect.ziaEstimation.prediction,
                prospect.ziaEstimation.predictionEnd
              )}
            </span>
            <span className="newLeads_prospects_list_item_valeurVMZ">
              {formatter.format(prospect.ziaEstimation.predictionEnd)}
            </span>
          </span>
        </div>
      </div>

      <div className="newLeads_propertyInfo_second flex-wrap flex-md-nowrap">
        <div className="newLeads_propertyInfo_second_child">
          <div>
            <span>{t("Leads.55")}</span>
            <span>{prospect.anneeConstruction}</span>
          </div>
          <div>
            <span>{t("Leads.56")}</span>
            <span>{prospect.typeBatiment}</span>
          </div>
          <div>
            <span>{t("Leads.60")}</span>
            <span>{prospect.superficieTerrain}</span>
          </div>
        </div>
        <div className="newLeads_propertyInfo_second_child">
          <div>
            <span>{t("Leads.57")}</span>
            <span>{prospect.pieces}</span>
          </div>
          <div>
            <span>{t("Leads.58")}</span>
            <span>{prospect.chambres}</span>
          </div>
          <div>
            <span>{t("Leads.59")}</span>
            <span>{prospect.bains}</span>
          </div>
        </div>
        <div className="newLeads_propertyInfo_second_child">
          <div>
            <span>{t("Leads.61")}</span>
            <span>{formatter.format(prospect.taxesMunicipale)}</span>
          </div>
          <div>
            <span>{t("Leads.62")}</span>
            <span>{formatter.format(prospect.evaluationMunicipale)}</span>
          </div>
        </div>
      </div>

      <div className="newLeads_propertyInfo_third">
        <span>{t("Leads.63")}</span>
        <span>{reformulateAdress(prospect.location.value, accepted)}</span>
      </div>
    </div>
  );
};

export default PropertyInfo;
