import { createSlice } from "@reduxjs/toolkit";

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState: {
    user: null,
    loadingUserData: true,
    loadingUserAccount: false,
    loading: false,
    email: "",
    password: "",
    error: null,
    authStatus: false,
    authEventOn: false,
    userData: null,
    accountData: null,
  },
  reducers: {
    setLoadingUserData: (state, action) => {
      state.loadingUserData = action.payload;
    },

    setLoadingUserAccount: (state, action) => {
      state.loadingUserAccount = action.payload;
    },

    setLoading: (state, action) => {
      state.loading = action.payload;
    },

    setUserImage: (state, action) => {
      state.userData.image = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    setUser: (state, action) => {
      state.user = action.payload;
    },

    setAuthStatus: (state, action) => {
      state.authStatus = action.payload;
    },

    setAuthEventOn: (state, action) => {
      state.authEventOn = action.payload;
    },

    setUserData: (state, action) => {
      state.userData = action.payload;
    },

    setAccountData: (state, action) => {
      state.accountData = action.payload;
    },

    setUserPersonalData: (state, action) => {
      state.userData.displayName = action.payload.displayName;
      state.userData.phoneNumber = action.payload.phoneNumber;
      state.userData.email = action.payload.email;
    },

    setUserInternetData: (state, action) => {
      state.userData.officialInformation.website = action.payload.website;
      state.userData.officialInformation.facebook = action.payload.facebook;
      state.userData.officialInformation.linkedIn = action.payload.linkedIn;
      state.userData.officialInformation.insta = action.payload.insta;
      state.userData.officialInformation.twitter = action.payload.twitter;
    },

    setUserLogo: (state, action) => {
      state.userData.logo = action.payload;
    },

    setRegulatoryDetails: (state, action) => {
      state.userData.regulatory = action.payload;
    },

    setUserMessages: (state, action) => {
      state.userData.messages = action.payload;
    },

    setIsToDelete: (state, action) => {
      state.accountData.isToDelete = action.payload;
    },

    setUserDataSlectedCities: (state, action) => {
      state.userData.slectedCities = action.payload;
    },
  },
});

export const {
  setUser,
  setError,
  setLoadingUserData,
  setLoadingUserAccount,
  setLoading,
  setAuthStatus,
  setAuthEventOn,
  setUserData,
  setAccountData,
  setUserImage,
  setUserPersonalData,
  setUserInternetData,
  setUserLogo,
  setRegulatoryDetails,
  setUserMessages,
  setIsToDelete,
  setUserDataSlectedCities,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
