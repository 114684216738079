import { functions, db } from "../../../../firebase";
import { doc, updateDoc } from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { setUserDataSlectedCities } from "../../authentication/authenticationSlice";
import {
  setAll,
  setReports,
  setEvaluations,
  setNextProspectId,
  setLoadingLeads,
  setUserFilters,
} from "../leadsSlice";

export const getUserDataAction = () => {
  return async (dispatch, getState) => {
    const { userFilters, limit } = getState().leads;
    const { userData } = getState().authentication;

    if (!userData || !userFilters) return;

    const removeAccents = (str) =>
      str
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();

    const compareArrays = (arr1, arr2) => {
      if (arr1.length !== arr2.length) return false;
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    };

    let selectedCitiesInFilterSummary = [];
    // let selectedMunicipalites = [];
    if (userFilters?.filterSummary) {
      selectedCitiesInFilterSummary = Object.keys(userFilters.filterSummary);
    }

    const formattedCities = selectedCitiesInFilterSummary.map((city) =>
      removeAccents(city)
    );

    if (!userData?.slectedCities && selectedCitiesInFilterSummary.length > 0) {
      const userRef = doc(db, "users", userData?.id);
      await updateDoc(userRef, {
        slectedCities: formattedCities,
      });

      await dispatch(setUserDataSlectedCities(formattedCities));
    } else if (
      userData?.slectedCities &&
      !compareArrays(userData?.slectedCities, formattedCities)
    ) {
      const userRef = doc(db, "users", userData?.id);
      await updateDoc(userRef, {
        slectedCities: formattedCities,
      });

      await dispatch(setUserDataSlectedCities(formattedCities));
    }

    // if (!userData?.selectedMunicipalites && selectedMunicipalites.length > 0) {
    //   const userRef = doc(db, "users", user);
    //   await updateDoc(userRef, {
    //     selectedMunicipalites: selectedMunicipalites,
    //   });
    // } else if (
    //   compareArrays(userData?.selectedMunicipalites, selectedMunicipalites)
    // ) {
    //   const userRef = doc(db, "users", user);
    //   await updateDoc(userRef, {
    //     selectedMunicipalites: selectedMunicipalites,
    //   });
    // }

    // check if userData?.selectedMunicipalites and selectedMunicipalites are not equal
    // if not equal, update the selectedMunicipalites in the user document

    // if (
    //   !userFilters?.slectedCities &&
    //   selectedCitiesInFilterSummary.length > 0
    // ) {
    //   dispatch(
    //     setUserFilters({
    //       ...userFilters,
    //       slectedCities: selectedCitiesInFilterSummary,
    //     })
    //   );
    // }

    // if (
    //   !userFilters?.selectedMunicipalites &&
    //   selectedMunicipalites.length > 0
    // ) {
    //   dispatch(
    //     setUserFilters({
    //       ...userFilters,
    //       selectedMunicipalites: selectedMunicipalites,
    //     })
    //   );
    // }

    dispatch(setLoadingLeads(true));
    let filterSummary = userFilters?.filterSummary;
    let newUserFilters = userFilters;
    if (userFilters?.municipalite && !userFilters.filterSummary) {
      filterSummary = {
        Québec: userFilters?.municipalite
          .split(",")
          .map((item) => ({ label: item, value: item })),
      };
      newUserFilters = { ...userFilters, filterSummary };
      setUserFilters(newUserFilters);
    }
    const fetchNewLeads = httpsCallable(functions, "newleads");
    fetchNewLeads({ ...newUserFilters, limit })
      .then((res) => {
        const reports = res.data.data || [];
        const filtered = reports.map((v) => {
          if (v.ouiContacterParProfessionnel === "oui") v.type = "contact";
          else v.type = "prospect";
          return v;
        });
        dispatch(setAll(filtered));
        dispatch(setReports(filtered));
        dispatch(setEvaluations(filtered));
        dispatch(setNextProspectId(res.data.nextProspectId));
        dispatch(setLoadingLeads(false));
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
};
